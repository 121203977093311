import { useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Fragment } from "react";
import { Outlet } from "react-router";

const ContentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

const OutletWrapper = styled("div")(({ theme }) => ({
  // height: "100%",
  display: "flex",
  justifyContent: "flex-start",
  flex: 1,
  justifyContent: "center",
  // alignItems: "center",
  background: theme.palette.primary.grey700,
}));

const LayoutV2 = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Fragment>
      <ContentWrapper>
        <OutletWrapper>{children || <Outlet />}</OutletWrapper>
      </ContentWrapper>
    </Fragment>
  );
};

export default LayoutV2;