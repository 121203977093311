import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import RTL from "components/RTL";
import useSettings from "hooks/useSettings";
import { createCustomTheme } from "./theme";
import routes from "./routes";
import "./i18n";
import globalcss from "styles/global.css";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { saveSetting } from "utils/database";
import { useEffect } from "react";

const App = () => {
  const router = createBrowserRouter(routes());
  const { settings } = useSettings();
  const theme = createCustomTheme({
    theme: settings.theme,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
  });


  useEffect(() => {
    axios.get(`https://api.${window.location.hostname}/v1/setting`).then((res) => {
      saveSetting(res.data.data)
    })
  }, [])
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <RTL>
          <CssBaseline />
          <RouterProvider router={router} />
          <ToastContainer />
        </RTL>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
