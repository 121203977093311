import React from "react";
import ReactDOM from "react-dom/client";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import SettingsProvider from "contexts/settingsContext";
import App from "./App";
import "nprogress/nprogress.css";
import "simplebar-react/dist/simplebar.min.css";
import "pure-react-carousel/dist/react-carousel.es.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { HelmetProvider } from 'react-helmet-async';
import {Provider} from "react-redux";
import store from "./redux/configStore";
import {PersistGate} from "redux-persist/integration/react";
import persistStore from "redux-persist/es/persistStore";
const persistor = persistStore(store)

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
    <I18nextProvider i18n={i18n}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </I18nextProvider>
    </PersistGate>
    </Provider>

);
