import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  kr: {
    translation: {
      forsea_company_introduction_title: "회사 소개",
      forsea_company_introduction_description:
        "저희 유니언포씨는 혁신적인 수산물 거래 플랫폼으로, 어민과 상인, 그리고 소비자를 하나로 연결하는 최적의 솔루션을 제공합니다.\n\
수년간의 경험과 기술력을 바탕으로 수산물 유통의 투명성, 효율성, 그리고 신뢰성을 향상시키기 위해 최선을 다하고 있습니다.\n\
\n\
저희 플랫폼은 전국의 어민들과의 직거래를 통해 신선하고 품질 좋은 수산물을 합리적인 가격에 제공하며, 빠르고 안전한 배송 서비스로 고객 만족도를 극대화하고 있습니다.\n\
또한, 수산물의 실시간 거래 데이터를 제공하여 거래의 투명성을 보장하고, 상인들이 정확한 시장 정보를 바탕으로 최상의 결정을 내릴 수 있도록 돕습니다.",

      forsea_business_introduction_title: "업무 안내",
      forsea_business_introduction_description: `저희 유니언포씨는 혁신적인 수산물 거래 플랫폼으로, 어민과 상인, 그리고 소비자를 하나로 연결하는 최적의 솔루션을 제공합니다.
수년간의 경험과 기술력을 바탕으로 수산물 유통의 투명성, 효율성, 그리고 신뢰성을 향상시키기 위해 최선을 다하고 있습니다.

저희 플랫폼은 전국의 어민들과의 직거래를 통해 신선하고 품질 좋은 수산물을 합리적인 가격에 제공하며, 빠르고 안전한 배송 서비스로 고객 만족도를 극대화하고 있습니다. 
또한, 수산물의 실시간 거래 데이터를 제공하여 거래의 투명성을 보장하고, 상인들이 정확한 시장 정보를 바탕으로 최상의 결정을 내릴 수 있도록 돕습니다.`,

      fukunari_company_introduction_title: "회사 소개",
      fukunari_company_introduction_description:
        "저희 FUKUNARI는 혁신적인 수산물 거래 플랫폼으로, 어민과 상인, 그리고 소비자를 하나로 연결하는 최적의 솔루션을 제공합니다.\n\
수년간의 경험과 기술력을 바탕으로 수산물 유통의 투명성, 효율성, 그리고 신뢰성을 향상시키기 위해 최선을 다하고 있습니다.\n\
\n\
저희 플랫폼은 전국의 어민들과의 직거래를 통해 신선하고 품질 좋은 수산물을 합리적인 가격에 제공하며, 빠르고 안전한 배송 서비스로 고객 만족도를 극대화하고 있습니다.\n\
또한, 수산물의 실시간 거래 데이터를 제공하여 거래의 투명성을 보장하고, 상인들이 정확한 시장 정보를 바탕으로 최상의 결정을 내릴 수 있도록 돕습니다.",

      fukunari_business_introduction_title: "업무 안내",
      fukunari_business_introduction_description: `저희 FUKUNARI는 혁신적인 수산물 거래 플랫폼으로, 어민과 상인, 그리고 소비자를 하나로 연결하는 최적의 솔루션을 제공합니다.
수년간의 경험과 기술력을 바탕으로 수산물 유통의 투명성, 효율성, 그리고 신뢰성을 향상시키기 위해 최선을 다하고 있습니다.

저희 플랫폼은 전국의 어민들과의 직거래를 통해 신선하고 품질 좋은 수산물을 합리적인 가격에 제공하며, 빠르고 안전한 배송 서비스로 고객 만족도를 극대화하고 있습니다. 
또한, 수산물의 실시간 거래 데이터를 제공하여 거래의 투명성을 보장하고, 상인들이 정확한 시장 정보를 바탕으로 최상의 결정을 내릴 수 있도록 돕습니다.`,

      how_to_use_title: "사용 안내",
      how_to_use_description: `저희 유니언포씨는 혁신적인 수산물 거래 플랫폼으로, 어민과 상인, 그리고 소비자를 하나로 연결하는 최적의 솔루션을 제공합니다.
수년간의 경험과 기술력을 바탕으로 수산물 유통의 투명성, 효율성, 그리고 신뢰성을 향상시키기 위해 최선을 다하고 있습니다.

저희 플랫폼은 전국의 어민들과의 직거래를 통해 신선하고 품질 좋은 수산물을 합리적인 가격에 제공하며, 빠르고 안전한 배송 서비스로 고객 만족도를 극대화하고 있습니다. 
또한, 수산물의 실시간 거래 데이터를 제공하여 거래의 투명성을 보장하고, 상인들이 정확한 시장 정보를 바탕으로 최상의 결정을 내릴 수 있도록 돕습니다.`,

      caution_title: "주의사항",
      caution_description: `저희 유니언포씨는 혁신적인 수산물 거래 플랫폼으로, 어민과 상인, 그리고 소비자를 하나로 연결하는 최적의 솔루션을 제공합니다.`,

      contact: "연락처",
      register_consultation: "상담내용 등록하기",
    },
  },
  jp: {
    translation: {
      forsea_company_introduction_title: "会社の紹介",
      forsea_company_introduction_description:
        "私たちの会社は、創新の水産業取引プラットフォームです。農民、商人、そして消費者を一つに結びつける最適なソリューションを提供します。",

      forsea_business_introduction_title: "業務案内",
      forsea_business_introduction_description:
        "私たちの会社は、創新の水産業取引プラットフォームです。農民、商人、そして消費者を一つに結びつける最適なソリューションを提供します。",

      fukunari_company_introduction_title: "会社の紹介",
      fukunari_company_introduction_description:
        "私たちの会社は、創新の水産業取引プラットフォームです。農民、商人、そして消費者を一つに結びつける最適なソリューションを提供します。",

      fukunari_business_introduction_title: "業務案内",
      fukunari_business_introduction_description:
        "私たちの会社は、創新の水産業取引プラットフォームです。農民、商人、そして消費者を一つに結びつける最適なソリューションを提供します。",

      how_to_use_title: "使用案内",
      how_to_use_description:
        "私たちの会社は、創新の水産業取引プラットフォームです。農民、商人、そして消費者を一つに結びつける最適なソリューションを提供します。",

      caution_title: "注意事項",
      caution_description:
        "私たちの会社は、創新の水産業取引プラットフォームです。農民、商人、そして消費者を一つに結びつける最適なソリューションを提供します。",

      contact: "連絡先",
      register_consultation: "相談内容の登録",
    },
  },
};
i18next.use(initReactI18next).init({
  resources,
  lng: "kr",
  fallbackLng: "kr",
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
